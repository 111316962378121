import { minmax } from "frontend/geometry/utils";
import { prop } from "frontend/utils/fn-utils";
import { clamp } from "frontend/utils/math-utils";
import { equals, keys, values } from "rambda";
import { TableCellsInfo } from "./table-info";

export interface CellKey {
  col: string;
  row: string;
}

export type TableSelection =
  | null
  | { type: "set"; cells: CellKey[] }
  | { type: "rect"; start: CellKey; end: CellKey }
  | { type: "cols"; colKeys: string[] }
  | { type: "rows"; rowKeys: string[] };

export interface TableState {
  isEditing: boolean;
  cursor: null | CellKey;
  selection: TableSelection;
}

type TableActionType =
  | "dblclick"
  | "start-edit"
  | "end-edit"
  | "toggle-cell"
  | "reset-on-cell"
  | "reset"
  | "select-col"
  | "select-row"
  | "move-cursor"
  | "extend-selection";

export interface TableAction {
  type: TableActionType;
}

function assertNever(x: never): never {
  throw new Error("Unhandled case");
}

function* cellsInRect(start: CellKey, end: CellKey, element: any) {
  let colStart = element.cols.findIndex(({ id }: { id: string }) => id == start.col);
  let rowStart = element.rows.findIndex(({ id }: { id: string }) => id == start.row);
  let colEnd = element.cols.findIndex(({ id }: { id: string }) => id == end.col);
  let rowEnd = element.rows.findIndex(({ id }: { id: string }) => id == end.row);
  [colStart, colEnd] = minmax(colStart, colEnd);
  [rowStart, rowEnd] = minmax(rowStart, rowEnd);
  for (let col = Math.max(colStart, 0); col <= Math.min(colEnd, element.cols.length - 1); col++)
    for (let row = Math.max(rowStart, 0); row <= Math.min(rowEnd, element.rows.length - 1); row++) {
      yield { col: element.cols[col].id, row: element.rows[row].id };
    }
}

// TODO: should return coordinates in numbers since that's more useful for the callers
// but sometimes keys is also useful :-/
export function* cellsInSelection(selection: TableSelection, element: any) {
  if (selection == null) return;
  switch (selection.type) {
    case "set":
      yield* selection.cells;
      break;
    case "rect":
      yield* cellsInRect(selection.start, selection.end, element);
      break;
    case "cols":
      for (let col of selection.colKeys) for (let { id: row } of element.rows) yield { col, row };
      break;
    case "rows":
      for (let { id: col } of element.cols) for (let row of selection.rowKeys) yield { col, row };
      break;
    default:
      assertNever(selection);
  }
}

/**
 * Checks if selection is of full-columns (entire column along the table) and returns the column keys.
 * Will return null otherwise, if selection contains at least 1 columns that is not fully selected.
 */
export function getColumnsThatAreFullySelected(selection: TableSelection, info: TableCellsInfo): null | string[] {
  if (selection == null) return null;
  switch (selection.type) {
    case "cols":
      return selection.colKeys.length ? selection.colKeys : null;
    case "rows":
      // if all the rows are selected, we treat that as if all columns are selected
      if (selection.rowKeys.length == info.numRows) return [...info.cols()].map(prop("key"));
    case "rect":
    case "set":
      // Count how man cells are selected in each column
      // then check if that's the entire length of the table
      const countCols = {} as Record<string, number>;
      for (const cell of selectionCells(selection, info)) {
        countCols[cell.col.key] = (countCols[cell.col.key] ?? 0) + 1;
      }
      if (values(countCols).every(equals(info.numRows))) return keys(countCols);
      return null;
    default:
      assertNever(selection);
  }
}
/**
 * Checks if selection is of full-rows (entire row along the table) and returns the row keys.
 * Will return null otherwise, if selection contains at least 1 row that is not fully selected.
 */
export function getRowsThatAreFullySelected(selection: TableSelection, info: TableCellsInfo): null | string[] {
  if (selection == null) return null;
  switch (selection.type) {
    case "cols":
      // if all the columns are selected, we treat that as if all rows are selected
      if (selection.colKeys.length == info.numRows) return [...info.rows()].map(prop("key"));
      return null;
    case "rows":
      return selection.rowKeys.length ? selection.rowKeys : null;
    case "rect":
    case "set":
      // Count how man cells are selected in each row
      // then check if that's the entire length of the table
      const countRows = {} as Record<string, number>;
      for (const cell of selectionCells(selection, info)) {
        countRows[cell.row.key] = (countRows[cell.row.key] ?? 0) + 1;
      }
      if (values(countRows).every(equals(info.numColumns))) return keys(countRows);
      return null;
    default:
      assertNever(selection);
  }
}

export function* selectionCells(selection: TableSelection, info: TableCellsInfo) {
  if (selection == null) return;
  switch (selection.type) {
    case "set":
      yield* info.infoForCells(selection.cells);
      break;
    case "rect":
      {
        let colStart = info.colIndex(selection.start.col);
        let rowStart = info.rowIndex(selection.start.row);
        let colEnd = info.colIndex(selection.end.col);
        let rowEnd = info.rowIndex(selection.end.row);
        [colStart, colEnd] = minmax(colStart, colEnd);
        [rowStart, rowEnd] = minmax(rowStart, rowEnd);
        for (let col = Math.max(colStart, 0); col <= Math.min(colEnd, info.numColumns - 1); col++)
          for (let row = Math.max(rowStart, 0); row <= Math.min(rowEnd, info.numRows - 1); row++) {
            yield info.cell(info.colKey(col), info.rowKey(row));
          }
      }
      break;
    case "cols":
      yield* info.getCellsInIntersection(selection.colKeys, undefined);
      break;
    case "rows":
      yield* info.getCellsInIntersection(undefined, selection.rowKeys);
      break;
    default:
      assertNever(selection);
  }
}

function toggleValueInArray<T>(arr: T[], value: T, equalsFn?: (i: T) => boolean): void {
  equalsFn ||= equals(value);
  let index = arr.findIndex(equalsFn);
  if (index == -1) arr.push(value);
  else arr.splice(index, 1);
}

function extendSelectionRectTo(draft: TableState, colrow: CellKey) {
  if (draft.selection == null || draft.selection.type != "rect") {
    draft.selection = { type: "rect", start: colrow, end: colrow };
  } else {
    draft.selection.end = colrow;
  }
}

function toggleCell(draft: TableState, cell: CellKey, element: any) {
  if (draft.selection?.type != "set") {
    let currentlySelected = [...cellsInSelection(draft.selection, element)];
    draft.selection = { type: "set", cells: currentlySelected };
  }
  toggleValueInArray(draft.selection.cells, cell);
}

export function isSelectionEmpty(selection: TableSelection): boolean {
  if (selection == null) return true;
  switch (selection.type) {
    case "set":
      return selection.cells.length == 0;
    case "rect":
      return false;
    case "cols":
      return selection.colKeys.length == 0;
    case "rows":
      return selection.rowKeys.length == 0;
    default:
      assertNever(selection);
  }
}

export function initialTableState() {
  return {
    isEditing: false,
    cursor: null,
    selection: null,
  } as TableState;
}

export function tableReducer(draft: TableState, action: TableAction & Record<string, any>) {
  switch (action.type) {
    case "start-edit":
      draft.isEditing = draft.cursor != null;
      break;
    case "end-edit":
      draft.isEditing = false;
      break;
    case "reset":
      draft.isEditing = false;
      draft.cursor = null;
      draft.selection = null;
      break;
    case "extend-selection":
      draft.isEditing = false;
      draft.cursor = { col: action.col, row: action.row };
      extendSelectionRectTo(draft, draft.cursor);
      break;
    case "toggle-cell":
      draft.isEditing = false;
      draft.cursor = { col: action.col, row: action.row };
      toggleCell(draft, { col: action.col, row: action.row }, action.element);
      break;
    case "select-col":
      draft.isEditing = false;
      if (draft.selection?.type != "cols") {
        draft.selection = { type: "cols", colKeys: [action.col] };
      } else {
        if (action.toggle) toggleValueInArray(draft.selection.colKeys, action.col);
        else draft.selection = { type: "cols", colKeys: [action.col] };
      }
      break;
    case "select-row":
      draft.isEditing = false;
      if (draft.selection?.type != "rows") {
        draft.selection = { type: "rows", rowKeys: [action.row] };
      } else {
        if (action.toggle) toggleValueInArray(draft.selection.rowKeys, action.row);
        else draft.selection = { type: "rows", rowKeys: [action.row] };
      }
      break;
    case "reset-on-cell":
      draft.isEditing = false;
      draft.cursor = { col: action.col, row: action.row };
      draft.selection = { type: "rect", start: draft.cursor, end: draft.cursor };
      break;
    case "move-cursor":
      if (draft.cursor) {
        const { dx, dy, element } = action;
        let x = element.cols.findIndex(({ id }: { id: string }) => id == draft.cursor!.col);
        let y = element.rows.findIndex(({ id }: { id: string }) => id == draft.cursor!.row);
        x = clamp(x + dx, 0, element.cols.length - 1);
        y = clamp(y + dy, 0, element.rows.length - 1);
        draft.cursor = { col: element.cols[x].id, row: element.rows[y].id };
        draft.selection = { type: "rect", start: draft.cursor, end: draft.cursor };
      }
      break;
  }
}
