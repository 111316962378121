import { StickyNoteMarginX, StickyNoteMarginY } from "frontend/canvas-designer-new/elements/sticky-note-element";
import { lerp2, Point } from "frontend/utils/math-utils";
import { Degrees, Radians, toDegrees, toRadians } from "frontend/utils/transform";
import consts, { TypeCanvasElement, TypeShape } from "shared/consts";
import { CanvasElement, Shape, StickyNote, TaskCard } from "shared/datamodel/schemas";
import { Transform, cossin, diamondPoints, hexagonPoints, trianglePoints, ITransform, distance } from "./utils";
import AllShapes from "frontend/data/shapes/shapes-visuals";
import { parsePath } from "./svg-path-utils";
import { CubicCurve, QuadraticCurve } from "frontend/utils/connector-utils";
import { rotated90 } from "frontend/utils/point-utils";

/**
 * This file contains the closestT function, which is used to calculate the closest point on a element's outline
 * to a given position (usually mouse position).
 */

/**
 * All data about closest point on outline
 */
interface IClosestPointOnOutline {
  /** closest point coordinates */
  xy: Point;
  /** closest point parameter (0..1) */
  t: number;
  /** distance from outline to point */
  distance: number;
  /** normal direction at outline's closest point (degrees) */
  normalDirectionDegrees: number;
}

// I'll have a lot of functions like this
type ElementToClosestTFn = (element: any) => (p: Point) => IClosestPointOnOutline;

const none: IClosestPointOnOutline = Object.freeze({
  distance: Infinity,
  normalDirectionDegrees: 0,
  t: 0,
  xy: { x: 0, y: 0 },
});

const notImplemented = () => () => none;

const vec2_to_point= ([x, y]: [number, number]) => ({ x, y });

/**
 * Finds the closest point from (x,y) to segment (x1,y1,x2,y2)
 * @param x point x
 * @param y point y
 * @param x1 segment first point x
 * @param y1 segment first point y
 * @param x2 segment second point x
 * @param y2 segment second point y
 * @returns t:: 0 <= t <= 1 - t=0 is (x1,y1), t=1 is (x2,y2)
 */
function forSegment(x: number, y: number, x1: number, y1: number, x2: number, y2: number) {
  const segmentSqrLen = (x2 - x1) ** 2 + (y2 - y1) ** 2;
  if (segmentSqrLen == 0) return 0;
  var t = ((x - x1) * (x2 - x1) + (y - y1) * (y2 - y1)) / segmentSqrLen;
  t = Math.max(0, Math.min(1, t));
  return t;
}

/**
 * Find the closest point on a line loop (series of closed lines,last point connects to first)
 * Limitations: no self-intersecting polygons, line segments must be equal length
 * @param p the point
 * @param sx scale X factor for the coordinates of the line loop
 * @param sy scale Y factor for the coordinates of the line loop
 * @param points [x,y,x,y,...] - the line loop
 * @returns IClosestPointOnOutline
 */
function forLineLoop(p: Point, sx: number, sy: number, points: number[]): IClosestPointOnOutline {
  const NPoints = points.length / 2;
  let
    best_distance = Infinity,
    best_pointnum = 0,
    best_fraction = 0,
    best_xy: [number, number] = [0, 0];

  for (let cur = 0, prev = NPoints - 1; cur < NPoints; prev = cur, cur++) {
    // x1,y1,x2,y2 is the current line segment
    const x1 = points[prev*2] * sx, y1 = points[prev*2 + 1] * sy;
    const x2 = points[cur*2] * sx, y2 = points[cur*2 + 1] * sy;
    // check the distance from p to the line segment
    const t = forSegment(p.x, p.y, x1, y1, x2, y2);
    const xy = lerp2(x1, y1, x2, y2, t);
    const sqrDistance = (p.x - xy[0]) ** 2 + (p.y - xy[1]) ** 2;
    // save the current best
    if (sqrDistance < best_distance) {
      best_distance = sqrDistance;
      best_pointnum = prev;
      best_fraction = t;
      best_xy = xy;
    }
  }

  const t = (best_pointnum + best_fraction) / NPoints;
  const x1 = points[best_pointnum * 2] * sx, y1 = points[best_pointnum * 2 + 1] * sy;
  const x2 = points[((best_pointnum + 1) * 2) % points.length] * sx, y2 = points[((best_pointnum + 1) * 2 + 1) % points.length] * sy;
  let direction_x = x2 - x1, direction_y = y2 - y1;
  // direction is the vector along the segment we're closest to.
  // normal to that segment is rotated -90 => [y, -x]
  // and the angle is atan2(y,x) => atan2(-x, y)
  const rotation = Math.atan2(-direction_x, direction_y);

  return {
    t,
    xy: vec2_to_point(best_xy),
    distance: Math.sqrt(best_distance),
    normalDirectionDegrees: toDegrees(rotation as Radians)
  };
}

/**
 * calculate closest point on rectangle's outline to a given point
 * @param element the rect info
 * @returns closest point on rectangle's outline
 */
const forRect = (element: ITransform & { width: number, height: number }) => {
  const { width = 1, height = 1 } = element as any;
  const fn = forPolygon([0, 0, width, 0, width, height, 0, height])({ ...element, radius: 1 });
  return (p: Point) => {
    let q = fn(p);
    q.t -= 0.125; // t=0 should be top-center, not top-left, so I offset it by 0.125
    return q;
  }
}

/**
 * This function is supposed to find the closest point on an ellipse to a given point.
 * It is faulty - this isn't the algorithm to find closest point on ellipse.
 * For some insights, look at https://math.stackexchange.com/questions/475436/2d-point-projection-on-an-ellipse
 * This implementation is very simply, precise enough for our needs, and fast.
 * Also, it's very easy to invert it and calculate the point given 't'.
 * @param element
 * @returns IClosestPointOnOutline
 */
const forCircle = (element: Shape) => {
  const tr = Transform.of(element);
  return (p: Point) => {
    const p2 = tr.invPoint(p);
    const theta = Math.atan2(p2.y, p2.x);
    const t = (theta + Math.PI / 2) / (2 * Math.PI);
    const xy = tr.point(cossin(theta, element.radius || 1));
    const correctNormal = tr.normal(cossin(theta))
    const normalAngle = toDegrees(Math.atan2(correctNormal.y, correctNormal.x) as Radians)
    return { t, xy, distance: distance(p.x, p.y, xy.x, xy.y), normalDirectionDegrees: normalAngle };
  }
}

const forPolygon = (lineLoop: number[]) => (element: ITransform & { radius?: number }) => {
  // we'll move the point to the reference frame of the polygon, but without scale
  // scaling changes the space and distances, so I'll apply scale to the polygon instead
  const tr = new Transform(element.x, element.y, element.rotate, 1, 1);
  return (p: Point) => {
    const p2 = tr.invPoint(p);
    const { scaleX = 1, scaleY = 1, radius = 1 } = element;
    const rx = radius * scaleX;
    const ry = radius * scaleY;
    const { t, xy, distance, normalDirectionDegrees } = forLineLoop(p2, rx, ry, lineLoop);
    const q = tr.point(xy);
    return { t, xy: q, distance, normalDirectionDegrees: tr.rotateAngle(normalDirectionDegrees as Degrees) };
  }
}

const forCustomShape = (element: Shape) => {
  if (!element.subtype || !(element.subtype in AllShapes)) return notImplemented();

  let parsedOutlineOfShape: ReturnType<typeof parsePath> | null = null;
  parsedOutlineOfShape = parsePath(AllShapes[element.subtype as keyof typeof AllShapes].outline);


  const tr = new Transform(element.x, element.y, element.rotate, 1, 1);
  const { scaleX = 1, scaleY = 1 } = element;
  const bbox = AllShapes[element.subtype as keyof typeof AllShapes].viewbox;
  const ofsx = bbox[0] + bbox[2] / 2, ofsy = bbox[1] + bbox[3] / 2;

  // prepare all the data I need to calculate the closest point,
  // in the form of functions for each segment in the shape's outline.
  // each function will return the closest point on the segment assuming it's better than the current best
  const data = parsedOutlineOfShape!.map(({ cmd, points }) => {
    const N = points.length;
    let points2 = new Array<number>(N);
    for (let i = 0; i < N; i += 2) {
      points2[i] = (points[i] - ofsx) * scaleX;
      points2[i + 1] = (points[i + 1] - ofsy) * scaleY;
    }
    return { cmd, points: points2 }
  });

  // don't compute the lengths and totalLength if they're not needed, just prepare the array
  let lengths: null | number[] = null;
  let totalLength = 0;


  type FnToFindBest = (p: Point, best: null | any) => any;

  let closestForSegment: Array<FnToFindBest> = new Array(data.length)

  for (let index = 0; index < data.length; index++) {
    // create a function to evaluate the closest point on the segment
    let fn: FnToFindBest;
    const { cmd, points } = data[index];
    switch (cmd) {
      case 'L':
        fn = (m: Point, best: null | any) => {
          const p = closestToSegment(m.x, m.y, points[0], points[1], points[2], points[3]);
          const d = distance(m.x, m.y, p.x, p.y);
          if (best == null || best.distance > d) {
            // normal for line with slope dy/dx, has slope -dx/dy
            let normal;
            {
              const x1 = points[0];
              const y1 = points[1];
              const x2 = points[2];
              const y2 = points[3];
              const dx = x2 - x1;
              const dy = y2 - y1;
              normal = { x: dy, y: -dx };
            }
            best = { element, index, distance: d, point: p, normal };
          }
          return best;
        }
        break;
      case 'C':
      case 'Q':
        let b = cmd == 'C' ? new CubicCurve(
          points[0], points[1],
          points[2], points[3],
          points[4], points[5],
          points[6], points[7],
        ) : new QuadraticCurve(
          points[0], points[1],
          points[2], points[3],
          points[4], points[5]);
          let curvePoints = Array.from({ length: 101 }, (_, i) => b.point(i / 100));
          let closestPoint = (m: Point) => {
            let d = Number.MAX_SAFE_INTEGER;
            let index = 0;
            for (let i = 0; i < curvePoints.length - 1; i++) {
              let dnow = distance(m.x, m.y, curvePoints[i][0], curvePoints[i][1]);
              if (dnow < d) {
                d = dnow;
                index = i;
              }
            }
            return { d, t: index / 101, index, x: curvePoints[index][0], y: curvePoints[index][1] };
          }
          fn = (m, best) => {
            const closest = closestPoint(m);
            if (best == null || best.distance > (closest.d ?? Infinity)) {
              const point = { x: closest.x, y: closest.y, t: closest.t! }
              let tangent = { x: 0, y: 0 };
              let dt = 0;
              if (index > 0) {
                tangent.x += curvePoints[index][0] - curvePoints[index - 1][0];
                tangent.y += curvePoints[index][1] - curvePoints[index - 1][1];
                dt += 1 / curvePoints.length;
              }
              if (index < curvePoints.length) {
                tangent.x += curvePoints[index + 1][0] - curvePoints[index][0];
                tangent.y += curvePoints[index + 1][1] - curvePoints[index][1];
                dt += 1 / curvePoints.length;
              }
              const normal = rotated90(tangent);
              normal.x = -normal.x;
              normal.y = -normal.y;
              best = { element, index, distance: closest.d!, point, normal };
            }
            return best;
          }
        break;
      default:
        const unhandled_case: never = cmd;
        throw new Error(unhandled_case)
    }
    closestForSegment[index] = fn;
  }

  return (p: Point) => {
    // transform the point from canvas coordinates to element local coordinates (without scale)
    const mouselocal = tr.invPoint(p);

    // find closest point on element's outline to the mouselocal point
    let best = closestForSegment.reduce<any>((acc, fn) => fn(mouselocal, acc), null)
    if (!best) return none; // should never happen - there's always a closest point

    const normalAngle = toDegrees(Math.atan2(best.normal.y, best.normal.x) as Radians) + element.rotate

    return {
      xy: tr.point(best.point),
      distance: best.distance,
      normalDirectionDegrees: normalAngle,
      get t() {
        if (lengths == null) {
          lengths = parsedOutlineOfShape!.map(({ cmd, points }) => {
            if (cmd == 'L') return distance(points[0], points[1], points[2], points[3]);
            if (cmd == 'C') return new CubicCurve(
              points[0], points[1],
              points[2], points[3],
              points[4], points[5],
              points[6], points[7],
            ).length();
            if (cmd == 'Q') return new QuadraticCurve(
              points[0], points[1],
              points[2], points[3],
              points[4], points[5]).length();
            return 0;
          });
          totalLength = lengths.reduce((a, b) => a + b, 0);
        }
        // adjust the 't' value we find from being a value within a single segment, to a global value
        let l = 0;
        for (let index = 0; index < best.index; index++)
          l += lengths[index];
        l += best.point.t * lengths[best.index];
        l /= totalLength;


        // TODO: save l0 in the cache, so I don't have to calculate it again
        // we need l0 when we change the shape-type, and we have to adjust from old-shape t to new-shape t
        // It makes more sense to compute it only when needed.
        // Also, I might be able to get away with simple raycasting to find the closest point on the new shape
        //
        // t=0 is different for each shape, so let's normalize so t=0 is the top of the shape
        let t0 = closestForSegment.reduce<any>((acc, fn) => fn({ x: 0, y: -9999 }, acc), null)
        let l0 = 0;
        for (let index = 0; index < t0.index; index++)
          l0 += lengths[index];
        l0 += t0.point.t * lengths[t0.index];
        l0 /= totalLength;
        return l - l0;
      },
    };
  }
}

const shapeDispatch: Record<TypeShape, ElementToClosestTFn> = {
  [consts.SHAPES.RECT]: forRect,
  [consts.SHAPES.RECT_ROUNDED]: forRect,
  [consts.SHAPES.CIRCLE]: forCircle,
  [consts.SHAPES.DIAMOND]: forPolygon(diamondPoints),
  [consts.SHAPES.TRIANGLE]: forPolygon(trianglePoints),
  [consts.SHAPES.HEXAGON]: forPolygon(hexagonPoints),
}

const forShape = (element: Shape) => element.type == consts.CANVAS_ELEMENTS.SHAPE ?
  forCustomShape(element) :
  shapeDispatch[element.type as TypeShape](element);

const forStickyNote = (element: StickyNote) => forRect({ ...element, width: element.width + StickyNoteMarginX, height: element.height + StickyNoteMarginY });

const forTaskCard = (element: TaskCard) => forRect({
  x: element.x,
  y: element.y,
  width: consts.DEFAULTS.CARD_WIDTH,
  height: element.height ?? consts.DEFAULTS.CARD_HEIGHT,
  rotate: 0,
});

function transformNormal(tr: Omit<ITransform, 'x' | 'y'>, normal: Point, out?: Point) {
  // transforming normal by inverse scale and rotate
  const { rotate = 0, scaleX = 1, scaleY = 1 } = tr;
  let { x, y } = normal;
  x /= scaleX;
  y /= scaleY;
  if (rotate) {
    const rad = toRadians(rotate as Degrees);
    const { x: cos, y: sin } = cossin(rad);
    [x, y] = [x * cos - y * sin, x * sin + y * cos];
  }
  if (out == undefined) out = { x: 0, y: 0 }
  out.x = x;
  out.y = y;
  return out;
}


/**
 * Finds the closest point from (x,y) to segment (x1,y1,x2,y2)
 * @param x point x
 * @param y point y
 * @param x1 segment first point x
 * @param y1 segment first point y
 * @param x2 segment second point x
 * @param y2 segment second point y
 * @returns t:: 0 <= t <= 1 - t=0 is (x1,y1), t=1 is (x2,y2)
 */
function closestTToSegment(x: number, y: number, x1: number, y1: number, x2: number, y2: number) {
  const segmentSqrLen = (x2 - x1) ** 2 + (y2 - y1) ** 2;
  if (segmentSqrLen == 0) return 0;
  var t = ((x - x1) * (x2 - x1) + (y - y1) * (y2 - y1)) / segmentSqrLen;
  t = Math.max(0, Math.min(1, t));
  return t;
}

function closestToSegment(x: number, y: number, x1: number, y1: number, x2: number, y2: number) {
  const t = closestTToSegment(x, y, x1, y1, x2, y2);
  return {
    t,
    x: x1 + t * (x2 - x1),
    y: y1 + t * (y2 - y1),
  };
}

const dispatch: Record<TypeCanvasElement, ElementToClosestTFn> = {
  shape: forShape,
  stickyNote: forStickyNote,
  file: forRect,
  taskCard: forTaskCard,
  textBlock: forRect,
  frame: forRect,

  connector: notImplemented,
  drawing: notImplemented,
  comment: notImplemented,
  mindmap: notImplemented,
  mindmapOrgChart: notImplemented,
  orgChartRoot: notImplemented,
  orgChartNode: notImplemented,
  integrationItem: notImplemented,
  cardStack: notImplemented,
  liveIntegration: notImplemented,
  timeline: notImplemented,
  table: notImplemented,
  tableCell: notImplemented,
};

const closestT = (type: TypeCanvasElement, element: CanvasElement) => dispatch[type](element);

export default closestT;