import { canvasElementPrefix, fontPropertiesToString, konvaTextDecoration } from "shared/util/utils";
import { TableTextSchema, TypeTableCell } from "shared/datamodel/schemas/table";
import { CSSProperties, useMemo, useRef } from "react";
import { useSubscribe } from "replicache-react";
import { customAlphabet } from "nanoid";
import { deepEqual } from "frontend/utils/fn-utils";
import { ReadonlyJSONObject, ReadTransaction } from "@workcanvas/reflect";
import { Reflect } from "@workcanvas/reflect/client";
import { M } from "shared/datamodel/mutators";
import consts from "shared/consts";

export const cellPadding = 50; // maybe make this function of font-size?
// TODO: duplicated from table.ts
export const cellNanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 10);

const tableCellsPrefix = (tableId: string) => "cElement-tableCell-" + tableId;

export function fullCellKey(tableId: string, col: string, row: string): string {
  // check and extract just the nanoid, without the type
  if (tableId.startsWith(consts.CANVAS_ELEMENTS.TABLE))
    tableId = tableId.slice(consts.CANVAS_ELEMENTS.TABLE.length + 1);
  // return the full key
  return tableCellsPrefix(tableId) + "/" + col + "-" + row;
}

export class TableIds {
  static prefixRegexp = new RegExp(`^(${canvasElementPrefix})?${consts.CANVAS_ELEMENTS.TABLE}-`);

  constructor(public readonly tableId: string) {
    this.tableId = this.tableId.replace(TableIds.prefixRegexp, ""); // take just the nanoid part of the key
  }
  justTableId() {
    return this.tableId;
  }
  fullTableId() {
    return canvasElementPrefix + consts.CANVAS_ELEMENTS.TABLE + "-" + this.tableId;
  }
  commonPrefix() {
    return "tableCell-" + this.tableId;
  }
  commonPrefixInReflect() {
    return canvasElementPrefix + this.commonPrefix();
  }
  shortId(col: string, row: string) {
    return this.commonPrefix() + `/${col}-${row}`;
  }
  longId(col: string, row: string) {
    return canvasElementPrefix + this.shortId(col, row);
  }
  static getColAndRow(id: string): { col: string, row: string } {
    const match = /\/(\w+)-(\w+)$/.exec(id);;
    if (!match) {
      throw new Error("invalid id");
    }
    return { col: match[1], row: match[2] };
  }
}


export function computeCoordinatesOfLines(
  lines: { id: string; size: number }[],
  overrideSize: null | Record<string, number>,
  scale: number = 1
) {
  const ps = new Array(lines.length + 1);
  ps[0] = 0;
  for (let i = 0; i < lines.length; i++) {
    ps[i + 1] = lines[i].size * scale;
  }
  if (overrideSize) {
    for (let i = 0; i < lines.length; i++) {
      if (overrideSize[lines[i].id]) {
        ps[i + 1] = overrideSize[lines[i].id] * scale;
      }
    }
  }
  for (let i = 1; i < ps.length; i++) {
    ps[i] = ps[i - 1] + ps[i];
  }
  return ps;
}

const regexpToMatchKey = /^(?:cElement-)?tableCell-(\w+)\/(\w+)-(\w+)/;
export function splitKey(key: string) {
  const match = regexpToMatchKey.exec(key);
  if (!match) return { tableId: "", col: "", row: "" };
  return {
    tableId: match[1],
    col: match[2],
    row: match[3],
  };
}

// TODO: unite this and the next function to 1 hook
export function useSubscribeCellsData(
  rep: Reflect<M> | undefined,
  id: string,
  allElements?: Record<string, any>
): Record<string, TypeTableCell> {
  if (allElements) {
    return allElements; //allElements should have the cells objects, so we can just return it
  }
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const prefix = new TableIds(id).commonPrefixInReflect();
      return Object.fromEntries((await tx.scan({ prefix }).entries().toArray()) as [string, TypeTableCell][]);
    },
    {} as Record<string, TypeTableCell>
  );
}

export function useSubscribeContainedElements(
  rep: Reflect<M> | undefined,
  ids: string[],
  allElements?: Record<string, any>
) {
  const queryRef = useRef(ids);
  const memoizedIds = deepEqual(queryRef.current, ids) ? queryRef.current : (queryRef.current = ids);

  if (allElements) {
    return useMemo(
      () =>
        memoizedIds.reduce((acc, id) => {
          acc["cElement-" + id] = allElements["cElement-" + id];
          return acc;
        }, {} as Record<string, any>),
      [memoizedIds]
    );
  }
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      let x: ReadonlyJSONObject;
      let values = await Promise.all(ids.map((id) => tx.get("cElement-" + id)));
      return values.reduce((acc, v, index) => {
        if (typeof v === "object" && !Array.isArray(v) && v !== null && !(v as any).hidden) {
          acc[ids[index]] = v;
        }
        return acc;
      }, {} as Record<string, any>);
    },
    [],
    [memoizedIds]
  );
}
