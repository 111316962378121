import React, { memo } from "react";
import { Line } from "react-konva";
import { useUnmount } from "react-use";

type ColResizeEvent = { type: "drag" | "drag-end"; col: number; x: number; startSize: number; prevX: number };
type RowResizeEvent = { type: "drag" | "drag-end"; row: number; y: number; startSize: number; prevY: number };

interface Props {
  id: string;
  xs: number[];
  ys: number[];
  onMouseEnter: (kind:"col"|"row") => void;
  onMouseLeave: (e?: any) => void;
  onColResize: (e: ColResizeEvent) => number;
  onRowResize: (e: RowResizeEvent) => number;
}

export const DraggableTableLines = memo(
  ({ id, xs, ys, onMouseEnter, onMouseLeave, onColResize, onRowResize }: Props) => {
    useUnmount(() => onMouseLeave());

    const totalHeight = ys[ys.length - 1];
    const totalWidth = xs[xs.length - 1];
    const verticalLinePoints = [0, 0, 0, totalHeight];
    const horizontalLinePoints = [0, 0, totalWidth, 0];

    return (
      <>
        {xs.map(
          (x, i) =>
            i > 0 && (
              <Line
                key={`hline-${i}-${id}`}
                name="anchor"
                nth={i}
                prevX={xs[i - 1]}
                x={x}
                points={verticalLinePoints}
                stroke={"transparent"}
                strokeScaleEnabled={false}
                hitStrokeWidth={15}
                draggable={true}
                onMouseEnter={() => onMouseEnter("col")}
                onMouseLeave={onMouseLeave}
                onDragStart={(e) => {
                  e.currentTarget.attrs.start = xs[i];
                  e.currentTarget.attrs.startSize = xs[i] - xs[i - 1];
                }}
                onDragMove={(e) => {
                  const line = e.currentTarget;
                  line.y(0);
                  const newX = onColResize({
                    type: "drag",
                    col: line.attrs.nth,
                    x: line.x(),
                    startSize: line.attrs.startSize,
                    prevX: line.attrs.prevX,
                  });
                  line.x(newX);
                  e.cancelBubble = true;
                }}
                onDragEnd={(e) => {
                  const line = e.currentTarget;
                  onColResize({
                    type: "drag-end",
                    col: line.attrs.nth,
                    x: line.x(),
                    startSize: line.attrs.startSize,
                    prevX: line.attrs.prevX,
                  });
                }}
              />
            )
        )}
        {ys.map(
          (y, i) =>
            i > 0 && (
              <Line
                key={`vline-${i}-${id}`}
                name="anchor"
                nth={i}
                prevY={ys[i - 1]}
                y={y}
                points={horizontalLinePoints}
                stroke={"transparent"}
                hitStrokeWidth={15}
                strokeScaleEnabled={false}
                draggable={true}
                onMouseEnter={() => onMouseEnter("row")}
                onMouseLeave={onMouseLeave}
                onDragStart={(e) => {
                  e.currentTarget.attrs.start = ys[i];
                  e.currentTarget.attrs.startSize = (ys[i] - ys[i - 1]);
                }}
                onDragMove={(e) => {
                  const line = e.currentTarget;
                  line.x(0);
                  const newY = onRowResize({
                    type: "drag",
                    row: line.attrs.nth,
                    y: line.y(),
                    startSize: line.attrs.startSize,
                    prevY: line.attrs.prevY,
                  });
                  line.y(newY);
                  e.cancelBubble = true;
                }}
                onDragEnd={(e) => {
                  const line = e.currentTarget;
                  onRowResize({
                    type: "drag-end",
                    row: line.attrs.nth,
                    y: line.y(),
                    startSize: line.attrs.startSize,
                    prevY: line.attrs.prevY,
                  });
                }}
              />
            )
        )}
      </>
    );
  }
);
