import { KonvaEventObject } from "konva/types/Node";
import * as Actions from "./action-builder";

const tableActionsBasis = Object.freeze({
  click: { col: 0, row: 0, event: {} as KonvaEventObject<MouseEvent> },
  dblClick: { col: 0, row: 0, event: {} as KonvaEventObject<MouseEvent> },
  colResize: { col: 0, newSize: 0, startSize: 0 },
  colResizeEnd: { col: 0, newSize: 0, startSize: 0 },
  rowResize: { row: 0, newSize: 0, startSize: 0 },
  rowResizeEnd: { row: 0, newSize: 0, startSize: 0 },
  cellEdited: { colId: "string", rowId: "string", cur: null as any, initial: null as any },
  delete: void 0,
  rowColDragEnd: { item: "row" as "row" | "col", src: 0, target: 0 },
  addRow: { row: 0 },
  addCol: { col: 0 },
  extendSelection: { dx: 0, dy: 0 },
  selectRow: { row: 0, toggle: true },
  selectCol: { col: 0, toggle: true },
  moveCursor: { dx: 0, dy: 0 },
  clipboard: {} as ClipboardEvent,
  styleChange: { trait: "string", value: null as any },
  editTitle: { title: "string" },
});

export type TableActionsMap = typeof tableActionsBasis;
export type TableActionName = keyof TableActionsMap;

// Define TableAction type based on the action map
export type TableAction = {
  [K in keyof TableActionsMap]: TableActionsMap[K] extends void
    ? { type: K }
    : {
        type: K;
        payload: TableActionsMap[K];
      };
}[keyof TableActionsMap];

export type ActionHandler = (action: TableAction) => void;
export type ActionHandlers = { [K in TableActionName]: (action: TableActionsMap[K]) => void };

export const actions = Actions.createActionBuilders(tableActionsBasis);
