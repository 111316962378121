import React, { useState } from "react";
import { Text } from "react-konva";
import styles from "./table-title.module.css";
import { Html } from "react-konva-utils";
import { useAtomValue } from "jotai";
import { posScaleAtom } from "state-atoms";
import ClickDragDetectorGroup from "frontend/canvas-designer-new/elements/click-drag-detector-group";
import { ActionHandler, actions } from "./table-actions";

export function TableTitle({
  id,
  element,
  dispatch,
}: {
  id: string;
  element: { x: number; y: number; title: string };
  dispatch: ActionHandler;
}) {
  const [hover, setHover] = useState(false);
  const [editing, setEditing] = useState(false);
  const posScale = useAtomValue(posScaleAtom);
  const scale = posScale.scale;
  const s2 = Math.min(scale, 1);

  const x = element.x;
  const y = element.y - 24 - 16 / scale;

  if (scale < 0.1) {
    return null;
  }

  // Note: I'm positioning the html myself, without using Konva's positioning, because I treat the stage's scale differently.
  // if scale<1, I want to ignore the scale of the stage.
  // if scale>1, I do want it
  return editing ? (
    <Html
      transform={false}
      divProps={{
        style: {
          position: "fixed",
          top: 0,
          left: 0,
          transform: `translate(${(x - 3) * scale + posScale.x}px, ${(y - 4) * scale + posScale.y}px)
                      scale(${Math.max(scale, 1)})`,
          transformOrigin: "top left",
        },
      }}
    >
      <label className={styles.responsiveInputContainer}>
        <input
          type="text"
          placeholder="Table name..."
          defaultValue={element.title}
          autoFocus
          onFocus={(e) => {
            e.currentTarget.select();
            (e.currentTarget!.parentNode! as any).dataset.value = e.currentTarget.value || "Table name...";
          }}
          onInput={(e) =>
            ((e.currentTarget!.parentNode! as any).dataset.value = e.currentTarget.value || "Table name...")
          }
          onKeyDown={(e) => {
            if (e.key == "Enter" || e.key == "Escape") {
              dispatch(actions.editTitle({ title: e.currentTarget.value.trim() || "Table" }));
              setEditing(false);
            }
          }}
          onBlur={(e) => {
            dispatch(actions.editTitle({ title: e.currentTarget.value.trim() || "Table" }));
            setEditing(false);
          }}
        />
      </label>
    </Html>
  ) : (
    <ClickDragDetectorGroup
      onClick={() => setEditing(true)}
      x={x}
      y={y}
      id={id}
      isCanvasElement={true}
      isFrameTitle={true}
      isTableTitle={true}
      isFrame={false}
      isConnector={false}
      isConnectable={false}
      isSelectable={true}
      isTaskConvertible={false}
      element={element}
    >
      <Text
        height={12 / s2}
        verticalAlign="middle"
        text={element.title}
        fill={hover ? "#0072FF" : "#848199"}
        fontFamily="Poppins"
        fontSize={12 / s2}
        fontStyle="normal"
        fontVariant="normal"
        textDecoration="none"
        ellipsis={true}
        listening={true}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </ClickDragDetectorGroup>
  );
}
